import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';

Vue.config.productionTip = false

Vue.use(VueRouter);

const routes = [
    // 在这里定义你的路由
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

// const router = createRouter()
//
// router.beforeEach((to, from) => {
//     // ...
//     // 返回 false 以取消导航
//     return false
// })
//
// //通过路由守卫对请求进行处理
// router.beforeEach((to, from, next) => {
//     //如果地址中包含code(微信扫码后返回用于换取用户信息的临时票据)，则拦截并执行登录逻辑
//     if (window.location.href.indexOf('code') >= 0) {
//         //如果url中包含code,则保存到store中
//         let code = window.location.href.split("?")[1];
//         code = code.substring(5, code.indexOf('&'));
//         if (code != null && "" != code && code != undefined) {
//             console.log("开始执行微信登录")
//             //向后台登录接口发送数据
//             request.post('auth/login', {
//                 'code': code
//             }).then((r) => {
//                 //后端成功返回token则视为登录成功
//                 if (r.token) {
//                     Message.success('登录成功!');
//                     localStorage.setItem('token', r.token);
//                     localStorage.setItem('user', JSON.stringify(r.admin));
//                     console.log("登录成功")
//                     //进入网站欢迎页
//                     next('/');
//                 } else {
//                     Message.error('登录失败!');
//                 }
//             })
//         }
//     }
// })

new Vue({
    render: h => h(App),
    router,
}).$mount('#app')
