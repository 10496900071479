<template>
  <div id="app">
    <AccountRegister/>
  </div>
</template>

<script>
import AccountRegister from './components/AccountRegister.vue'

export default {
  name: 'App',
  components: {
      AccountRegister
  }
}
</script>

<style>
body, html {
    margin: 0; /* 重置默认边距为0 */
    padding: 0; /* 重置默认内边距为0 */
}
</style>
